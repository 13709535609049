import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'formRegisterPage', en);
i18next.addResourceBundle('es', 'formRegisterPage', es);

const FormRegister = lazy(() => import('./FormRegisterPage'));

const FormRegisterConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'form-register',
      element: <FormRegister />,
    },
  ],
};

export default FormRegisterConfig;
