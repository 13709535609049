const locale = {
  TITLE: 'Solicitudes pendientes',
  home: 'Inicio',
  homeTitle: 'Información del participante',
  representativeApproval: 'Aprobación apoderados',
  ASSETS: 'Activos',
  DISABLED: 'Desactivados',
  PENDING: 'Pendiente',
  addNewRepresentative: 'Crear apoderado',
  back: 'Volver',
  rutTitle: 'RUT',
  representativeNameTitle: 'Nombre apoderado',
  signaturePlaceTitle: 'Lugar de firma',
  notaryTitle: 'Notario',
  writingDateTitle: 'Fecha de escritura',
  actionsTitle: 'Acciones',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  approve: 'Aprobar',
  reject: 'Rechazar',
  deactivate: 'Desactivar',
  approveDeact: 'Aprobar desactivación',
  rejectDeact: 'Rechazar desactivación',
  noInformationToSee: 'No hay información para mostrar',
  messageConfirmation: 'Confirmar solicitud',
  successInfoDialogPrimaryMessage:
    'El apoderado quedó en estado pendiente de desactivación',
  approveMessage: 'Solicitud aprobada con éxito',
  rejectMessage: 'Solicitud rechazada con éxito',
  ALERT_NOTIFICATION: 'No puedes aprobar o rechazar tus propias solicitudes.',
};
export default locale;
