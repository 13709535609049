const locale = {
  TITLE: 'Register by File',
  home: 'Home',
  fileRegistration: 'Form by file',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  uploadTitle: 'UPLOAD',
  dragTitle: 'OR DRAG',
  uploadDescriptionFormat: 'CSV file format',
  uploadDescriptionMaximumSize: 'Maximum size 1MB',
  continue: 'Continue',
  errorMessage:
    'An error occurred. Please try again or you can contact us by calling (562) 2393 9001 or writing to ',
  csvFormatError: 'The document must be in CSV format.',
  fileSizeError: 'The file size is too large, it exceeds 1MB.',
  differentCsvFileError: 'Please try with a different CSV file.',
  emptyFileError: 'Empty file. Please select a file with data.',
  invalidFileError: 'Invalid file error',
  emptyContentError: 'File with no valid content.',
  fileReadError: 'Error reading the file.',
  downloadTemplate: 'Download Template',
};

export default locale;
