const locale = {
  TITLE: 'Duplicar por formulario',
  home: 'Inicio',
  search: 'Buscar',
  viewContract: 'Ver contrato',
  pageDescription:
    'Es un hecho bien establecido que un lector se distraerá con el contenido legible de una página.',
  back: 'Volver',
  duplicate: 'Duplicar',
};

export default locale;
