import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'participantsForm', en);
i18next.addResourceBundle('es', 'participantsForm', es);

const ParticipantsFormPage = lazy(() => import('./ParticipantsFormPage'));

const ParticipantsFormPageConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'relation-participants/form',
      element: <ParticipantsFormPage />,
    },
  ],
};

export default ParticipantsFormPageConfig;
