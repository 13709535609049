import { styled, useTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { memo, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { usePrevious } from '@fuse/hooks';
import _ from '@lodash';
import { useSelector } from 'react-redux';
import {
  selectIsUserGuest,
  selectUserSettings,
} from '../../../auth/user/store/userSlice';
import { useAuth } from '../../../auth/AuthRouteProvider';

const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: 80,
  right: 0,
  top: 160,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: 0,
  borderTopLeftRadius: 6,
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  zIndex: 999,
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: red[400],
  '&:hover': {
    backgroundColor: red[500],
  },

  '& .settingsButton': {
    '& > span': {
      animation: 'rotating 3s linear infinite',
    },
  },

  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

/**
 * The settings panel.
 */
function Configurator() {
  const theme = useTheme();
  const [open, setOpen] = useState('');
  const isUserGuest = useSelector(selectIsUserGuest);
  const userSettings = useSelector(selectUserSettings);
  const prevUserSettings = usePrevious(userSettings);

  const { updateUser } = useAuth();

  useEffect(() => {
    if (
      !isUserGuest &&
      prevUserSettings &&
      !_.isEqual(userSettings, prevUserSettings)
    ) {
      updateUser({ data: { settings: userSettings } });
    }
  }, [isUserGuest, userSettings]);

  const handlerOptions = {
    onSwipedLeft: () =>
      Boolean(open) && theme.direction === 'rtl' && handleClose(),
    onSwipedRight: () =>
      Boolean(open) && theme.direction === 'ltr' && handleClose(),
  };

  const settingsHandlers = useSwipeable(handlerOptions);
  const schemesHandlers = useSwipeable(handlerOptions);

  const handleOpen = (panelId: string) => {
    setOpen(panelId);
  };

  const handleClose = () => {
    setOpen('');
  };

  return <Root />;
}

export default memo(Configurator);
