const locale = {
  TITLE: 'Register by Form',
  home: 'Home',
  duplicate: 'Duplicate',
  formRegistration: 'Form Registration',
  enterInformation: 'Enter Information',
  validateInformation: 'Validate Information',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  identificationInformation: 'Identification Information',
  contractId: 'Contract ID',
  contractIdPlaceholder: 'Enter the information',
  contractDate: 'Trading Date',
  originator: 'Originator',
  ownerContract: 'Contract Owner',
  selectOption: 'Select an option',
  investor: 'Contract owner investor',
  investorPlaceholder: 'Search by code or name',
  counterpartyInvestor: 'Counterparty Investor',
  counterparty: 'Counterparty',
  counterpartyRUT: 'Counterparty RUT',
  businessInformation: 'Business Information',
  operationType: 'Operation Type',
  exchangeRatesType: 'Exchange Rates or Index',
  compensationCurrency: 'Compensation Currency',
  forwardPrice: 'Forward Price',
  paymentDay: 'Payment Day',
  subscriptionMaximumTerm: 'Subscription Deadline',
  transactionType: 'Transaction Type',
  complianceModality: 'Compliance Modality',
  contractedAmount: 'Notional Amount',
  agreedPrice: 'Agreed Price',
  paymentForm: 'Payment Form',
  expirationDate: 'Expiration Date',
  back: 'Back',
  continue: 'Continue',
  submitApplication: 'Submit Application',
  mandatoryField: 'Mandatory field',
  requiredField: 'Required field',
  alphaNumericError: 'In this field, you can only enter numbers and letters.',
  exceededIntegerDigits: 'Integer limit exceeded.',
  exceededDecimalDigits: 'Decimal limit exceeded.',
  invalidCharacter: 'Invalid character.',
  nonPositiveValue: 'The value must be positive.',
  zeroValueNotAllowed: 'Zero value is not allowed.',
  completeAllMandatory:
    'To duplicate the contract, all mandatory fields must be filled',
  completeAllMandatoryFieldsToModify:
    'To modify the contract, all mandatory fields must be filled',
  dateinputYear: 'yyyy',
  'calendar.today': 'Today',
  'dropdowns.nodata': 'NO DATA FOUND.',
  modify: 'Save',
  overallStatus: 'Overall Status',
  operationStatus: 'Operation Status',
  dcvFolio: 'DCV folio',
  idCounterpartyContract: 'Counterparty Contract Id',
  apiErrorMessage: 'An error has occurred. Please try again later.',
  'daterangepicker.cancel': 'Cancel',
  'daterangepicker.set': 'Select',
};

export default locale;
