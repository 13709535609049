const locale = {
  TITLE: 'Search',
  home: 'Home',
  title_table: 'Contract results',
  select_option: 'SELECT OPTION',
  more_filters: 'More Filters',
  counterparty_code: 'Counterparty code',
  counterparty_name: 'Counterparty name',
  counterparty: 'Counterparty',
  contract_owner: 'Contract owner',
  state_operation: 'Operation state',
  date_expiration_from: 'Expiration date from',
  date_expiration_to: 'Expiration date to',
  date_format: 'dd/mm/yyyy',
  id_contract: 'Id. Contract',
  waiting_signature: 'Waiting for my signature',
  type_operation: 'Operation type',
  date_contract_from: 'Contract date registered from',
  date_contract_to: 'Contract date registered to',
  dcv_id: 'Folio DCV',
  principal: 'Principal (associated with the participant)',
  secondary: 'Counterparty principal',
  transaction_type: 'Transaction type',
  see_contract: 'See Contract(s)',
  export_to_csv: 'Export to CSV',
  searchResults: 'search-results',
  authorize: 'Authorize',
  duplicate: 'Duplicate',
  subscribe: 'Subscribe',
  request: 'Request',
  file_box: 'File to box',
  anticipate: 'Anticipate',
  contractInformation: 'Contract Information',
  contractAdvanceData: 'Contract Advance Data',
  noInformationToSee: `There isn't any information to display`,
  folio_grid: 'Folio',
  id_contract_grid: 'Contract Id',
  contract_owner_grid_code: 'Contract owner code',
  contract_owner_grid_name: 'Contract owner name',
  date_exp_grid: 'Due date',
  state: 'State',
  nocional_amount: 'Nocional amount',
  pacted_price_grid: 'Pacted price',
  forward_price_grid: 'Forward price',
  reason_state_grid: 'Reason of state',
  transit_state_grid: 'Transit status',
  owner_principal_grid_code: 'Principal owner contract code',
  owner_principal_grid_name: 'Principal owner contract name',
  owner_secondary_grid_code: 'Counterparty principal code',
  owner_secondary_grid_name: 'Counterparty principal name',
  register_date_grid: 'Registration date',
  tryAnotherSearch: 'Try another search',
  ERROR_NOTIFICATION: 'An error has occurred. Please try again later.',
  onlyOneContractCanBeDuplicatedAtATime:
    'Only one contract can be duplicated at a time',
  contractsFailRequirements:
    'One or more contracts from the selection do not meet the requirements for subscription',
  contractsFailRequirementsAnnulment:
    'One or more contracts from the selection do not meet the requirements to be canceled',
  contractsFailRequirementsAdvance:
    'The selected contract does not meet the requirements for an advance',
  contractsFailRequirementsModify:
    'The selected contract does not meet the requirements for modification',
  due_date: 'Due date',
  no_options_available: 'No options available',
  modify: 'Modify',
  annulment: 'Annulment',
  requestIsBeingProcessed: 'The request is being processed',
};

export default locale;
