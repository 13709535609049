const locale = {
  Home: 'Inicio',
  Register: 'Registrar',
  RegisterByDocument: 'Registrar por documento',
  RegisterByForm: 'Registrar por formulario',
  Search: 'Buscar',
  ViewCounterpartyRequests: 'Ver solicitudes Contraparte',
  Reports: 'Reportes',
  ExpandCollapse: 'Expandir/Cerrar',
  RelationParticipant: 'Participantes',
  ApprovalRepresentatives: 'Apoderados',
};

export default locale;
