const locale = {
  home: 'Inicio',
  search: 'Buscar',
  requests: 'Solicitudes',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  contract: 'Contrato',
  viewContract: 'Ver contrato',
  back: 'Volver',
  register: 'Registrar',
  authorizeRegistration: 'Autorizar Registro',
  'grid.noRecords': 'No hay registros disponibles.',
};

export default locale;
