const locale = {
  TITLE: 'Reports',
  home: 'Home',
  placeholderSelect: 'Select an option',
  inputParticipantLabel: 'Participant',
  inputTypeLabel: 'Type',
  tryAnotherSearch: 'Try another search',
  noInformationToSee: 'No information to display',
  companyCode: 'Company Code',
  companyName: 'Company Name',
  reportName: 'Report Name',
  reportDate: 'Date',
  reportType: 'Type',
  physicalName: 'Physical Name',
  search: 'Search',
  exportInfo: 'files selected',
  export: 'Export',
  apiErrorMessage: 'An error has occurred. Please try again later.',
};

export default locale;
