import { memo } from 'react';

/**
 * The left side layout 2.
 */
function LeftSideLayout2() {
  return <div />;
}

export default memo(LeftSideLayout2);
