const locale = {
  TITLE: 'Relation among the participants',
  home: 'Home',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  searchTool: 'Search Tool',
  searchLabel: 'Search by company name or code',
  stateLabel: 'State',
  newRelation: 'New relation',
  newRecord: 'New record',
  rangeDate: 'Last update period range',
  placeHolderSearch: 'Search by code, company name or contract ID',
  placeholderSelect: 'Select',
  placeHolderRangePickerStart: 'Start date',
  placeHolderRangePickerEnd: 'End date',
  noInformationToSee: 'There isn’t any information to display',
  seeMore: 'See more',
  idContract: 'Contract ID',
  codParticipantFirst: 'Participant Code 1',
  codParticipantSecondary: 'Participant Code 2',
  participantFirst: 'Participant 1',
  participantSecondary: 'Participant 2',
  state: 'State',
  dateGeneralConditions: 'General conditions date',
  dateLastUpdate: 'Date for last update',
  noRegisterMessage: 'No records available',
  tryAnotherSearch: 'Try new search',
};

export default locale;
