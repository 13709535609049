const locale = {
  TITLE: 'Modificar',
  home: 'Inicio',
  search: 'Buscar',
  viewContract: 'Ver Contrato',
  back: 'Volver',
  modify: 'Modificar',
  initialContract: 'Contrato inicial',
  modifiedContract: 'Contrato modificado',
  modifiedFields: 'Campos modificados',
  reject: 'Rechazar',
  accept: 'Aceptar',
  confirm: 'Confirmar',
  subscribeModify: 'Subscribir modificación',
  authorizeModification: 'Autorizar modificación',
};

export default locale;
