const locale = {
  TITLE: 'Ver contrato',
  home: 'Inicio',
  search: 'Buscar',
  pageDescription:
    'Es un hecho bien establecido que un lector se distraerá con el contenido legible de una página.',
  back: 'Volver',
  contractId: 'ID contrato',
  LEGAL_DATA: 'DATOS LEGALES',
  REQUESTS: 'SOLICITUDES',
  MY_REQUESTS: 'MIS SOLICITUDES',
  COUNTERPARTY_REQUESTS: 'SOLICITUDES DE CONTRAPARTE',
  HISTORY: 'HISTORIAL',
  selectOption: 'SELECCIONAR OPCIÓN',
  print: 'Imprimir',
  download: 'Descargar',
  cancel: 'Cancelar',
  reject: 'Rechazar',
  void: 'Anular',
  advance: 'Anticipar',
  duplicate: 'Duplicar',
  modify: 'Modificar',
  authorize: 'Autorizar',
  subscribe: 'Suscribir',
  seeMore: 'Ver más',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  requestStatus: 'Estado de la solicitud',
  registrationDate: 'Fecha de registro',
  requestType: 'Tipo de solicitud',
  apiErrorMessage:
    'Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
  noInformationToSee: 'No hay información para mostrar',
  contractRegistration: 'Registro de Contrato',
  contractModification: 'Modificación de Contrato',
  contractCancellation: 'Anulación de Contrato',
  contractAdvance: 'Anticipo de Contrato',
  contractSubscription: 'Suscripción de Contrato',
  preparingPages: 'Preparando {{numLoadedPages}}/{{numPages}} páginas...',
  date: 'Fecha',
  type: 'Tipo',
  action: 'Acción',
  user: 'Usuario',
  contractOrRequestStatus: 'Estado contrato/solicitud',
  reasonForStatus: 'Razón de estado',
  invalidParameters: 'Parámetros inválidos',
};

export default locale;
