const locale = {
  Home: 'Home',
  Register: 'Register',
  RegisterByDocument: 'Register by Document',
  RegisterByForm: 'Register by Form',
  Search: 'Search',
  ViewCounterpartyRequests: 'View Counterparty Requests',
  Reports: 'Reports',
  ExpandCollapse: 'Expand/Collapse',
  RelationParticipant: 'Participants',
  ApprovalRepresentatives: 'Representatives',
};

export default locale;
