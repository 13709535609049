const locale = {
  TITLE: 'Participant information',
  home: 'Home',
  representativeApproval: 'Representative Approval',
  pendingRequest: 'Pending Requests',
  homeTitle: 'Create Record',
  back: 'Back',
  informationTitle: 'Information',
  companyName: 'Company Name',
  participantCode: 'Participant Code',
  rut: 'RUT',
  registerDataTitle: 'Record Data',
  inputName: 'Representative Name',
  insertInformation: 'Enter Information',
  inputRut: 'RUT',
  inputSignature: 'Signature Place',
  labelDateWritting: 'Writing Date',
  inputNotary: 'Notary',
  placeholderDateWritting: 'Select',
  confirm: 'Confirm',
  messageConfirmation: 'Confirm Request',
  successInfoDialogPrimaryMessage: 'Request Created Successfully',
  successInfoDialogSecondaryMessage:
    'The request has been successfully registered.',
  invalidRut: 'Invalid RUT',
  inputRequired: 'Required Field',
  onlyAlphabeticalCharacters: 'You can only enter letters in this field',
};

export default locale;
