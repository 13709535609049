const locale = {
  TITLE: 'Información del participante',
  home: 'Inicio',
  representativeApproval: 'Aprobación apoderados',
  pendingRequest: 'Solicitudes pendientes',
  homeTitle: 'Crear registro',
  back: 'Volver',
  informationTitle: 'Información',
  companyName: 'Razón social de la empresa',
  participantCode: 'Código de participante',
  rut: 'RUT',
  registerDataTitle: 'Datos del registro',
  inputName: 'Nombre apoderado',
  insertInformation: 'Ingresar información',
  inputRut: 'RUT',
  inputSignature: 'Lugar de firma',
  labelDateWritting: 'Fecha de escritura',
  inputNotary: 'Notario',
  placeholderDateWritting: 'Seleccionar',
  confirm: 'Confirmar',
  messageConfirmation: 'Confirmar solicitud',
  successInfoDialogPrimaryMessage: 'Solicitud creada con éxito',
  successInfoDialogSecondaryMessage:
    'Se ha registrado exitosamente la solicitud.',
  invalidRut: 'RUT inválido',
  inputRequired: 'Campo requerido',
  onlyAlphabeticalCharacters: 'En este campo solo puedes ingresar letras',
};
export default locale;
