type AppConfig = {
  name: string;
  apiUrl: string;
  apiLoginUrl: string;
  apiTokenUrl: string;
  isProd: boolean;
  mockApi: {
    enabled: boolean;
  };
};

const appConfig: AppConfig = {
  name: import.meta.env.VITE_APP_NAME,
  apiUrl: import.meta.env.VITE_NUAM_API_URL,
  apiLoginUrl: import.meta.env.VITE_NUAM_API_LOGIN_URL,
  apiTokenUrl: import.meta.env.VITE_NUAM_API_TOKEN_URL,
  isProd: import.meta.env.PROD,
  mockApi: {
    enabled: import.meta.env.VITE_APP_MOCK_API_ENABLED === 'true',
  },
};

export default appConfig;
