const locale = {
  home: 'Home',
  search: 'Search',
  requests: 'Requests',
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  contract: 'Contract',
  viewContract: 'View contract',
  back: 'Back',
  register: 'Register',
  authorizeRegistration: 'Authorize Registration',
  'grid.noRecords': 'No records available.',
};

export default locale;
