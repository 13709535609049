const locale = {
  TITLE: 'Duplicate by Form',
  home: 'Home',
  search: 'Search',
  viewContract: 'View Contract',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  back: 'Back',
  duplicate: 'Duplicate',
};

export default locale;
