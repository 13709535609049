import React, { useEffect, useRef } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

import {
  NotificationDetail,
  NotificationType,
  useMessage,
} from '../hooks/useMessage';
import useWindowSize from '../hooks/useWindowSize';
import CheckCircleOutlined from './../../../assets/Icons/CheckCircleOutlined.svg';
import ErrorOutline from './../../../assets/Icons/ErrorOutline.svg';
import ConfirmFormSubmissionIcon from './../../../assets/Icons/ConfirmFormSubmissionIcon.svg';
import InfoOutlined from './../../../assets/Icons/InfoOutlined.svg';
import ConfirmationInfoOutlined from './../../../assets/Icons/ConfirmationInfoOutlined.svg';

import styled from 'styled-components';
import './styles/kendoDialogStyles.css';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'dialogMessage', en);
i18next.addResourceBundle('es', 'dialogMessage', es);

interface MessageProps {
  color?: string;
  fontSize?: string;
}

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #2e7d32;
`;

const ResponsivedIcon = styled.img`
  height: 100px;
  width: auto;

  @media (max-width: 959px) {
    height: 80px;
    width: auto;
  }
`;

const BodyText = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #02111a;
  padding-bottom: 20px;
  padding-inline: 20px;

  @media (max-width: 959px) {
    font-size: 12px;
  }
`;

const Message = styled.p<MessageProps>`
  color: ${(props) => props.color || '#000'};
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (max-width: 959px) {
    font-size: ${(props) => props.fontSize || '14px'};
  }
`;

const notificationConfig = {
  success: {
    icon: CheckCircleOutlined,
    MessageComponent: Message,
    color: '#2e7d32',
    responsiveFontSize: '14px',
  },
  error: {
    icon: ErrorOutline,
    MessageComponent: Message,
    color: '#d32f2f',
    responsiveFontSize: '14px',
  },
  confirmation: {
    icon: ConfirmFormSubmissionIcon,
    MessageComponent: Message,
    color: '#003C71',
    responsiveFontSize: '18px',
  },
  confirmationInfo: {
    icon: ConfirmationInfoOutlined,
    MessageComponent: Message,
    color: '#1A39DA',
    responsiveFontSize: '18px',
  },
  info: {
    icon: InfoOutlined,
    MessageComponent: Message,
    color: '#0288D1',
    responsiveFontSize: '18px',
  },
};

export default function KendoDialog() {
  const { t: tDialogMessage } = useTranslation('dialogMessage');
  const { state, closeNotification } = useMessage();
  const isSmallScreen = useWindowSize(1200);
  const { success, error, warning, info, confirmationInfo, confirmation } =
    state;

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: isSmallScreen ? 'column' : 'row',
    justifyContent: 'center',
    margin: '10px 0',
    gap: '20px',
  };

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleCloseNotification = (type: NotificationType) => () => {
    closeNotification(type);
  };

  useEffect(() => {
    if (success || info) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        if (success) closeNotification('success');
        if (info) closeNotification('info');
      }, 5000);
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [success, info, closeNotification]);

  const renderNotification = (
    notification: NotificationDetail,
    type: NotificationType,
  ) => {
    const { icon, MessageComponent, color, responsiveFontSize } =
      notificationConfig[type];

    return (
      <Dialog title=" " onClose={handleCloseNotification(type)}>
        <DialogContent>
          {icon && <ResponsivedIcon src={icon} alt={`${type} Icon`} />}
          {MessageComponent && (
            <MessageComponent color={color} fontSize={responsiveFontSize}>
              {notification.message}
            </MessageComponent>
          )}
          {notification.secondaryMessage && (
            <BodyText style={{ fontWeight: 400 }}>
              {notification.secondaryMessage}
            </BodyText>
          )}
          {notification.contactInfo && (
            <BodyText style={{ fontWeight: 700 }}>
              {notification.contactInfo}
            </BodyText>
          )}
          {notification.showRetryButton && (
            <div className="kendo-buttons flex justify-center my-[10px]">
              <Button
                className="btn-primary min-w-[200px]"
                size="large"
                fillMode="solid"
                rounded="full"
                onClick={handleCloseNotification(type)}
              >
                {tDialogMessage(notification.retryButtonText ?? 'retryButton')}
              </Button>
            </div>
          )}
          {notification.buttonOrder && (
            <div className="kendo-buttons" style={containerStyle}>
              {notification.buttonOrder.map((buttonType) => {
                if (
                  buttonType === 'back' &&
                  notification.onBackButton &&
                  notification.backButtonText
                ) {
                  return (
                    <Button
                      key="back"
                      className="btn-secondary min-w-[180px]"
                      size="large"
                      fillMode="outline"
                      rounded="full"
                      onClick={notification.onBackButton}
                    >
                      {tDialogMessage(notification.backButtonText)}
                    </Button>
                  );
                } else if (
                  buttonType === 'confirm' &&
                  notification.onConfirmButton &&
                  notification.confirmButtonText
                ) {
                  return (
                    <Button
                      key="confirm"
                      className="btn-primary min-w-[180px]"
                      size="large"
                      fillMode="solid"
                      rounded="full"
                      onClick={notification.onConfirmButton}
                    >
                      {tDialogMessage(notification.confirmButtonText)}
                    </Button>
                  );
                }
                return null;
              })}
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <React.Fragment>
      {success && renderNotification(success, 'success')}
      {error && renderNotification(error, 'error')}
      {confirmation && renderNotification(confirmation, 'confirmation')}
      {confirmationInfo &&
        renderNotification(confirmationInfo, 'confirmationInfo')}
      {info && renderNotification(info, 'info')}
    </React.Fragment>
  );
}
