import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'registerPage', en);
i18next.addResourceBundle('es', 'registerPage', es);

const Register = lazy(() => import('./RegisterPage'));

const RegisterPageConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'register',
      element: <Register />,
    },
  ],
};

export default RegisterPageConfig;
