const esLocale = {
  WELCOME_TYPE_1: 'Bienvenido al',
  WELCOME_TYPE_2: 'Servicio Forward',
  ENTER_DATA: 'Ingresa los siguientes datos',
  RUT: 'RUT',
  USER: 'Usuario',
  PASSWORD: 'Contraseña',
  TOKEN: 'Token',
  PARTICIPANT_CODE: 'Código de participante',
  LOGIN: 'Ingresar',
  ERROR_NOTIFICATION:
    'Usuario, contraseña y/o token no válido. Contacte a su administrador de seguridad en caso que persista el error.',
  INFO_NOTIFICATION:
    'En caso de dudas contacta a la Mesa de Atención a Clientes, de lunes a viernes de 9:00 a 18:30 horas. Teléfono +56 22 3939001 o correo: mac@dcv.cl',
  NO_NAME: 'SIN NOMBRE PROPORCIONADO POR DCV EVO.',
};

export default esLocale;
