import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'duplicatePage', en);
i18next.addResourceBundle('es', 'duplicatePage', es);

const Duplicate = lazy(() => import('./DuplicatePage'));

const DuplicatePageConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'search/duplicate',
      element: <Duplicate />,
    },
  ],
};

export default DuplicatePageConfig;
