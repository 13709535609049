import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'pendingRequestPage', en);
i18next.addResourceBundle('es', 'pendingRequestPage', es);

const PendingRequest = lazy(() => import('./PendingRequestPage'));

const PendingRequestConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/representative-approval/pending-request',
      element: <PendingRequest />,
    },
  ],
};

export default PendingRequestConfig;
