import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'viewContractPage', en);
i18next.addResourceBundle('es', 'viewContractPage', es);

const ViewContract = lazy(() => import('./ViewContractPage'));

const ViewContractPageConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'search/view-contract',
      element: <ViewContract />,
    },
  ],
};

export default ViewContractPageConfig;
