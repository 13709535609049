const locale = {
  TITLE: 'Pending Requests',
  home: 'Home',
  homeTitle: 'Participant information',
  representativeApproval: 'Representative Approval',
  ASSETS: 'Assets',
  DISABLED: 'Disabled',
  PENDING: 'Pending',
  addNewRepresentative: 'Create Representative',
  back: 'Back',
  rutTitle: 'RUT',
  representativeNameTitle: 'Representative Name',
  signaturePlaceTitle: 'Signature Place',
  notaryTitle: 'Notary',
  writingDateTitle: 'Writing Date',
  actionsTitle: 'Actions',
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  approve: 'Approve',
  reject: 'Reject',
  deactivate: 'Deactivate',
  approveDeact: 'Approve deactivation',
  rejectDeact: 'Reject deactivation',
  noInformationToSee: 'There isn’t any information to display',
  messageConfirmation: 'Confirm request',
  successInfoDialogPrimaryMessage: 'The representative is pending deactivation',
  approveMessage: 'Request successfully approved',
  rejectMessage: 'Request successfully rejected',
  ALERT_NOTIFICATION: 'You cannot approve or reject your own requests.',
};

export default locale;
