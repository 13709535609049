import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import { FuseRouteConfigsType, FuseRoutesType } from '@fuse/utils/FuseUtils';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import Error404Page from '../main/404/Error404Page';
import ExampleConfig from '../main/example/ExampleConfig';
import FileRegisterConfig from '../main/file-register/FileRegisterPageConfig';
import FormRegisterConfig from '../main/form-register/FormRegisterPageConfig';
import HomeConfig from '../main/home/HomeConfig';
import ParticipantConfig from '../main/relation-participant/ParticipantPageConfig';
import ParticipantsFormPageConfig from '../main/relation-participant/participants-form/ParticipantsFormPageConfig';
import SearchConfig from '../main/search/SearchConfig';
import SubscriptionPageConfig from '../main/search/subscribe/SubscriptionPageConfig';
import DuplicatePageConfig from '../main/search/duplicate/DuplicatePageConfig';
import ModifyPageConfig from '../main/search/modify/ModifyPageConfig';
import ViewContractPageConfig from '../main/search/view-contract/ViewContractPageConfig';
import AnnulmentPageConfig from '../main/search/annulment/AnnulmentPageConfig';
import AnticipatePageConfig from '../main/search/anticipate/AnticipatePageConfig';
import RepresentativeConfig from '../main/representative-approval/RepresentativeApprovalPageConfig';
import CreateRepresentativeConfig from '../main/representative-approval/pending-request/create-representative/CreateRepresentativePageConfig';
import PendingRequestConfig from '../main/representative-approval/pending-request/PendingRequestPageConfig';
import ReportsPageConfig from '../main/reports/ReportsPageConfig';
import RegisterPageConfig from '../main/search/register/RegisterPageConfig';
import CounterpartyRequestsPageConfig from '../main/counterparty-requests/CounterpartyRequestsPageConfig';

const routeConfigs: FuseRouteConfigsType = [
  ExampleConfig,
  SignOutConfig,
  SignInConfig,
  FormRegisterConfig,
  HomeConfig,
  FileRegisterConfig,
  ParticipantConfig,
  ParticipantsFormPageConfig,
  SearchConfig,
  SubscriptionPageConfig,
  DuplicatePageConfig,
  ModifyPageConfig,
  ViewContractPageConfig,
  AnnulmentPageConfig,
  AnticipatePageConfig,
  RepresentativeConfig,
  CreateRepresentativeConfig,
  PendingRequestConfig,
  RegisterPageConfig,
  ReportsPageConfig,
  CounterpartyRequestsPageConfig,
];

/**
 * The routes of the application.
 */
const routes: FuseRoutesType = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth,
  ),
  {
    path: '/',
    element: <Navigate to="/home" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  },
];

export default routes;
