const locale = {
  Greeting: 'Hello',
  Participant: 'Participant',
  Organization: 'Banco BCI',
  SignIn: 'Sign In',
  SignUp: 'Sign Up',
  SignOut: 'Sign Out',
  Settings: 'Settings',
  Help: 'Help',
  LogOut: 'Log Out',
};

export default locale;
