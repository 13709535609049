import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/app/auth/user/store/userSlice';
import DCV from './assets/dcv.svg';
import DcvMovil from './assets/dcvMovil.svg';
import './assets/styles/UserNavbarHeader.css';

const Root = styled('div')(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function UserNavbarHeader({ isCollapsed }) {
  const user = useSelector(selectUser);

  return (
    <Root className="user relative flex flex-col items-center justify-center p-16 pb-14 shadow-0">
      <div className="mb-24 flex items-center justify-center">
        <img src={isCollapsed ? DcvMovil : DCV} />
      </div>
    </Root>
  );
}

export default UserNavbarHeader;
