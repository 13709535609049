const locale = {
  TITLE: 'Relación entre participantes',
  home: 'Inicio',
  pageDescription:
    'Es un hecho bien establecido que un lector se distraerá con el contenido legible de una página.',
  searchTool: 'Buscador',
  searchLabel: 'Buscar por razón social o código',
  stateLabel: 'Estado',
  newRelation: 'Nueva relación',
  newRecord: 'Nuevo registro',
  rangeDate: 'Rango de periodo última actualización',
  placeHolderSearch: 'Buscar por código, razón social o ID de contrato',
  placeholderSelect: 'Seleccionar',
  placeHolderRangePickerStart: 'Fecha inicio',
  placeHolderRangePickerEnd: 'Fecha fin',
  noInformationToSee: 'No hay información para mostrar',
  seeMore: 'Ver más',
  idContract: 'ID Contrato',
  codParticipantFirst: 'Código Participante 1',
  codParticipantSecondary: 'Código Participante 2',
  participantFirst: 'Participante 1',
  participantSecondary: 'Participante 2',
  state: 'Estado',
  dateGeneralConditions: 'Fecha condiciones generales',
  dateLastUpdate: 'Fecha de última actualización',
  noRegisterMessage: 'No hay registros disponibles',
  tryAnotherSearch: 'Intenta nueva búsqueda',
};

export default locale;
