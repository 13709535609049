const locale = {
  messages: 'Messages',
  home: 'Home',
  requests: 'Requests',
  back: 'Back',
  advance: 'Advance',
  cancellation: 'Cancellation',
  authorization: 'Authorization',
  modification: 'Modification',
  registration: 'Registration',
  subscription: 'Subscription',
  register: 'Register',
  modify: 'Modify',
  cancel: 'Cancel',
  anticipate: 'Anticipate',
  subscribe: 'Subscribe',
  authorize: 'Authorize',
  noInformationToSee: `There isn't any information to display`,
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  reject: 'Reject',
  negotiationDate: 'Negotiation Date',
  expirationDate: 'Expiration Date',
  operationType: 'Operation Type',
  transactionType: 'Transaction Type',
  contractOwner: 'Contract Owner',
  counterparty: 'Counterparty',
  contractOwnerInvestor: 'Contract Owner Investor',
  counterpartyInvestor: 'Counterparty Investor',
  apiErrorMessage: 'An error has occurred. Please try again later.',
};

export default locale;
