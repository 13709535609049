const locale = {
  TITLE: 'Relation among the participants',
  home: 'Home',
  search: 'Search',
  viewContract: 'View Contract',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  back: 'Back',
  contractId: 'Contract ID',
  contract: 'Contract',
  registryDate: 'Trading Date',
  originatorCode: 'Originator Code',
  originatorName: 'Originator Name',
  ownerContractCode: 'Owner Contract Code',
  ownerContractName: 'Owner Contract Name',
  investor: 'Contract owner investor',
  counterpartyCode: 'Counterparty Code',
  counterpartyName: 'Counterparty Name',
  counterpartyRUT: 'Counterparty RUT',
  investorCode: 'Contract Owner Investor Code',
  investorName: 'Contract Owner Investor Name',
  counterpartyInvestorCode: 'Counterparty Investor Code',
  counterpartyInvestorName: 'Counterparty Investor Name',
  operationType: 'Operation Type',
  exchangeRateType: 'Exchange Rates or Index',
  clearingCurrencyCode: 'Clearing Currency',
  forwardPrice: 'Forward Price',
  payDayCode: 'Pay Day',
  maxSubscriptionTermType: 'Max Subscription Term',
  stateReason: 'Reason for Status',
  transactionType: 'Transaction Type',
  complianceModeType: 'Compliance Modality',
  notionalAmount: 'Notional Amount',
  agreedPrice: 'Agreed Price',
  paymentMethodType: 'Payment Method',
  dueDate: 'Expiration Date',
  contractDate: 'Contract Date',
  status: 'Status',
  operationStatus: 'Operation Status',
  statusInTransit: 'Status in Transit',
  dcvFolio: 'Folio',
  afpTicker: 'AFP Ticker',
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  subscribe: 'Subscribe',
  recordCount: 'Number of Records:',
};

export default locale;
