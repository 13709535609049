import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  ReactNode,
} from 'react';

export type NotificationDetail = {
  message: string;
  secondaryMessage?: string;
  contactInfo?: ReactNode;
  showRetryButton?: boolean;
  retryButtonText?: string;
  onBackButton?: () => void;
  backButtonText?: string;
  onConfirmButton?: () => void;
  confirmButtonText?: string;
  buttonOrder?: string[];
};

type NotificationContent = NotificationDetail;

export type NotificationType =
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'confirmation'
  | 'confirmationInfo';

type NotificationState = {
  [key in NotificationType]?: NotificationContent;
};

type NotificationAction =
  | {
      type: 'DISPLAY_MESSAGE';
      messageType: NotificationType;
      content: NotificationContent;
    }
  | { type: 'CLOSE_NOTIFICATION'; messageType: NotificationType };

const initialState: NotificationState = {};

const NotificationContext = createContext<{
  state: NotificationState;
  displayMessage: (
    type: NotificationType,
    content: NotificationContent,
  ) => void;
  closeNotification: (type: NotificationType) => void;
}>({
  state: initialState,
  displayMessage: () => {},
  closeNotification: () => {},
});

type NotificationProviderProps = {
  children: ReactNode;
};

function notificationReducer(
  state: NotificationState,
  action: NotificationAction,
): NotificationState {
  switch (action.type) {
    case 'DISPLAY_MESSAGE':
      return { ...state, [action.messageType]: action.content };
    case 'CLOSE_NOTIFICATION':
      const newState = { ...state };
      delete newState[action.messageType];
      return newState;
    default:
      return state;
  }
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  const displayMessage = useCallback(
    (type: NotificationType, content: NotificationContent) => {
      dispatch({ type: 'DISPLAY_MESSAGE', messageType: type, content });
    },
    [],
  );

  const closeNotification = useCallback((type: NotificationType) => {
    dispatch({ type: 'CLOSE_NOTIFICATION', messageType: type });
  }, []);

  return (
    <NotificationContext.Provider
      value={{ state, displayMessage, closeNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useMessage = () => useContext(NotificationContext);
