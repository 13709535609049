import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useState, memo } from 'react';
import UserNavbarHeader from '../../../../shared-components/UserNavbarHeader';
import SideMenuAdmin from '../../../../shared-components/side-menu/components/SideMenuAdmin';
import './assets/styles/NavbarStyle.css';
import Arrow from './assets/ArrowLeft.svg';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  color: '#1A39DA',
  '& ::-webkit-scrollbar-thumb': {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.24)'
        : 'rgba(255, 255, 255, 0.24)'
    }`,
  },
  '& ::-webkit-scrollbar-thumb:active': {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.37)'
        : 'rgba(255, 255, 255, 0.37)'
    }`,
  },
}));

const StyledContent = styled(FuseScrollbars)(() => ({
  overscrollBehavior: 'contain',
  overflowX: 'hidden',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 10px',
  backgroundAttachment: 'local, scroll',
}));

type NavbarStyle2ContentProps = {
  className?: string;
};

const NavbarStyle2Content = ({ className }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Root
      className={clsx(
        'flex h-full flex-auto flex-col overflow-hidden',
        className,
        { 'is-collapsed': isCollapsed },
      )}
    >
      <div className="linea" />

      <StyledContent
        className="flex min-h-0 flex-1 flex-col"
        option={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <div
          className="header-container"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className="photo">
            <UserNavbarHeader isCollapsed={isCollapsed} />
          </div>
          <button
            className="hidden md:flex"
            onClick={toggleCollapse}
            style={{ marginLeft: 'auto' }}
          >
            <img src={Arrow} className={clsx({ 'rotate-180': isCollapsed })} />
          </button>
        </div>
        <SideMenuAdmin isCollapsed={isCollapsed} />
      </StyledContent>
    </Root>
  );
};

export default memo(NavbarStyle2Content);
