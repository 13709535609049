const locale = {
  TITLE: 'Companies',
  home: 'Home',
  homeTitle: 'Representative Approval',
  participantLabel: 'Participant',
  remainingAuthorizationLabel: 'Pending Authorization',
  lastDateUpdatedLabel: 'Last Update Date',
  companyCode: 'Company Code',
  companyName: 'Company Name',
  lastUpdate: 'Update Date',
  waitingAuthorization: 'Pending Authorization',
  seeMore: 'See More',
  placeholderSelect: 'Select',
  placeholderSelectParticipant: 'Company name, corporate name, company code',
  consult: 'Consult',
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  noInformationToSee: `There isn't any information to display`,
  tryAnotherSearch: 'Try another search',
};

export default locale;
