const locale = {
  back: 'Back',
  home: 'Home',
  title: 'Inter-participant Relations',
  information: 'Information',
  contractId: 'Contract ID',
  lastUpdateDate: 'Last Update Date',
  contractStatus: 'Contract Status',
  active: 'Active',
  inactive: 'Inactive',
  pending: 'Pending',
  pendingDeactivation: 'Pending Deactivation',
  generalConditionsDate: 'Date of General Conditions',
  dateOfRecord: 'Record Date',
  placeOfCompliance: 'Place of Fulfillment',
  referenceBanks: 'Reference Banks',
  referenceBank1: 'Business Name 1',
  referenceBank2: 'Business Name 2',
  referenceBank3: 'Business Name 3',
  participant1: 'Participant 1',
  participantCode: 'Participant Code',
  businessName: 'Legal Entity Name',
  participantRUT: 'Participant RUT',
  code: 'Code',
  RUT: 'RUT',
  principals: 'Principals',
  add: 'Add',
  register: 'Register',
  cancel: 'Cancel',
  save: 'Save',
  approve: 'Approve',
  reject: 'Reject',
  participant2: 'Participant 2',
  enterInformation: 'Enter Information',
  selectOption: 'Select an option',
  mandatoryField: 'Required field',
  requiredField: 'Required field',
  completeAllMandatory: 'To register, all mandatory fields must be completed',
  edit: 'Edit',
  deactivate: 'Deactivate',
  newRecord: 'New Record',
  searchTool: 'Search Tool',
  searchByCodeOrBusinessName: 'Search by code or business name',
  principalAlreadyAdded: 'Principal already added',
  errorFetchingParticipantData: 'Error fetching participant data',
  alphaNumericError: 'In this field, you can only enter numbers and letters',
  invalidCharacter: 'Invalid character',
};

export default locale;
