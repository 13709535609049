import { useState, useEffect } from 'react';

function useWindowSize(breakpoint: number = 960): boolean {
  const initialIsSmallScreen = window.innerWidth < breakpoint;
  const [isSmallScreen, setIsSmallScreen] = useState(initialIsSmallScreen);

  function handleResize() {
    setIsSmallScreen(window.innerWidth < breakpoint);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return isSmallScreen;
}

export default useWindowSize;
