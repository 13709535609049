const locale = {
  TITLE: 'Reportes',
  home: 'Inicio',
  placeholderSelect: 'Seleccionar una opción',
  inputParticipantLabel: 'Participante',
  inputTypeLabel: 'Tipo',
  tryAnotherSearch: 'Intenta otra búsqueda',
  noInformationToSee: 'No hay información para mostrar',
  companyCode: 'Código de empresa',
  companyName: 'Nombre de la empresa',
  reportName: 'Nombre del reporte',
  reportDate: 'Fecha',
  reportType: 'Tipo',
  physicalName: 'Nombre físico',
  search: 'Buscar',
  Active: 'Activos',
  Deactive: 'Desactivados',
  Pending: 'Pendientes',
  exportInfo: 'archivos seleccionados',
  export: 'Exportar',
  apiErrorMessage:
    'Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
};

export default locale;
