const locale = {
  back: 'Volver',
  home: 'Inicio',
  title: 'Relación entre participantes',
  information: 'Información',
  contractId: 'ID del contrato',
  lastUpdateDate: 'Fecha última actualización',
  contractStatus: 'Estado del contrato',
  active: 'Activo',
  inactive: 'Inactivo',
  pending: 'Pendiente',
  pendingDeactivation: 'Pendiente de Desactivación',
  generalConditionsDate: 'Fecha condiciones generales',
  dateOfRecord: 'Fecha de registro',
  placeOfCompliance: 'Lugar de cumplimiento',
  referenceBanks: 'Bancos de referencia',
  referenceBank1: 'Razón social 1',
  referenceBank2: 'Razón social 2',
  referenceBank3: 'Razón social 3',
  participant1: 'Participante 1',
  participantCode: 'Código de participante',
  businessName: 'Razón social',
  participantRUT: 'RUT Participante',
  code: 'Código',
  RUT: 'RUT',
  principals: 'Mandantes',
  add: 'Agregar',
  register: 'Registrar',
  cancel: 'Cancelar',
  save: 'Guardar',
  approve: 'Aprobar',
  reject: 'Rechazar',
  participant2: 'Participante 2',
  enterInformation: 'Ingresar información',
  selectOption: 'Seleccionar opción',
  mandatoryField: 'Campo obligatorio',
  requiredField: 'Campo requerido',
  completeAllMandatory:
    'Para registrar se deben completar todos los campos obligatorios',
  edit: 'Editar',
  deactivate: 'Desactivar',
  newRecord: 'Nuevo registro',
  searchTool: 'Buscador',
  searchByCodeOrBusinessName: 'Buscar por código o razón social',
  principalAlreadyAdded: 'Mandante ya agregado',
  errorFetchingParticipantData: 'Error al obtener datos del participante',
  alphaNumericError: 'En este campo solo puedes ingresar números y letras',
  invalidCharacter: 'Carácter inválido',
};

export default locale;
