const locale = {
  TITLE: 'View Contract',
  home: 'Home',
  search: 'Search',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  back: 'Back',
  contractId: 'Contract ID',
  LEGAL_DATA: 'LEGAL DATA',
  REQUESTS: 'REQUESTS',
  MY_REQUESTS: 'MY REQUESTS',
  COUNTERPARTY_REQUESTS: 'COUNTERPARTY REQUESTS',
  HISTORY: 'HISTORY',
  selectOption: 'SELECT OPTION',
  print: 'Print',
  download: 'Download',
  cancel: 'Cancel',
  reject: 'Reject',
  void: 'Void',
  advance: 'Advance',
  duplicate: 'Duplicate',
  modify: 'Modify',
  authorize: 'Authorize',
  subscribe: 'Subscribe',
  seeMore: 'See More',
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  requestStatus: 'Request Status',
  registrationDate: 'Registration Date',
  requestType: 'Request Type',
  apiErrorMessage: 'An error has occurred. Please try again later.',
  noInformationToSee: 'There isn’t any information to display',
  contractRegistration: 'Contract Registration',
  contractModification: 'Contract Modification',
  contractCancellation: 'Contract Cancellation',
  contractAdvance: 'Contract Advance',
  contractSubscription: 'Contract Subscription',
  preparingPages: 'Preparing {{numLoadedPages}}/{{numPages}} pages...',
  date: 'Date',
  type: 'Type',
  action: 'Action',
  user: 'User',
  contractOrRequestStatus: 'Contract/Request Status',
  reasonForStatus: 'Reason for Status',
  invalidParameters: 'Invalid Parameters',
};

export default locale;
