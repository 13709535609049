const locale = {
  messages: 'Mensajes',
  home: 'Inicio',
  requests: 'Solicitudes',
  back: 'Volver',
  advance: 'Anticipo',
  cancellation: 'Anulación',
  authorization: 'Autorización',
  modification: 'Modificación',
  registration: 'Registro',
  subscription: 'Suscripción',
  register: 'Registrar',
  modify: 'Modificar',
  cancel: 'Anular',
  anticipate: 'Anticipar',
  subscribe: 'Suscribir',
  authorize: 'Autorizar',
  noInformationToSee: 'No hay información para mostrar',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  reject: 'Rechazar',
  negotiationDate: 'Fecha de negociación',
  expirationDate: 'Fecha de vencimiento',
  operationType: 'Tipo de operación',
  transactionType: 'Tipo de transacción',
  contractOwner: 'Dueño de contrato',
  counterparty: 'Contraparte',
  contractOwnerInvestor: 'Mandante dueño de contrato',
  counterpartyInvestor: 'Mandante de contraparte',
  apiErrorMessage:
    'Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
};

export default locale;
