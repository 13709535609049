const locale = {
  TITLE: 'Modify',
  home: 'Home',
  search: 'Search',
  viewContract: 'View Contract',
  back: 'Back',
  modify: 'Modify',
  initialContract: 'Initial Contract',
  modifiedContract: 'Modified Contract',
  modifiedFields: 'Modified Fields',
  reject: 'Reject',
  accept: 'Accept',
  confirm: 'Confirm',
  subscribeModify: 'Subscribe Modification',
  authorizeModification: 'Authorize Modification',
};

export default locale;
