import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'representativePage', en);
i18next.addResourceBundle('es', 'representativePage', es);

const Representative = lazy(() => import('./RepresentativeApprovalPage'));

const RepresentativeConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'representative-approval',
      element: <Representative />,
    },
  ],
};

export default RepresentativeConfig;
