import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'annulmentPage', en);
i18next.addResourceBundle('es', 'annulmentPage', es);

const Annulment = lazy(() => import('./AnnulmentPage'));

const AnnulmentPageConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'search/annulment',
      element: <Annulment />,
    },
  ],
};

export default AnnulmentPageConfig;
