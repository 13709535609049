import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'anticipatePage', en);
i18next.addResourceBundle('es', 'anticipatePage', es);

const Anticipate = lazy(() => import('./AnticipatePage'));

const AnticipatePageConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'search/anticipate',
      element: <Anticipate />,
    },
  ],
};

export default AnticipatePageConfig;
