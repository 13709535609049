const locale = {
  TITLE: 'Empresas',
  home: 'Inicio',
  homeTitle: 'Aprobación apoderados',
  participantLabel: 'Participante',
  remainingAuthorizationLabel: 'Autorización pendiente',
  lastDateUpdatedLabel: 'Fecha última actualización',
  companyCode: 'Código empresa',
  companyName: 'Nombre empresa',
  lastUpdate: 'Fecha de actualización',
  waitingAuthorization: 'Autorización pendiente',
  seeMore: 'Ver más',
  placeholderSelect: 'Seleccionar',
  placeholderSelectParticipant:
    'Nombre de la empresa, razón social, código empresa',
  consult: 'Consultar',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  noInformationToSee: 'No hay información para mostrar',
  tryAnotherSearch: 'Intenta nueva búsqueda',
};
export default locale;
